import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet"
// import { withPrefix } from "gatsby"
import { navigate } from 'gatsby';
import Header from './Header'
import Footer from './Footer'
// import Navigation from './Navigation';
import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';
import * as ROUTES from '../constants/routes';
import {
  AuthUserContext,
} from '../components/Session';


class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');
    const firestore = import('firebase/firestore');
    const functions = import('firebase/functions');

    Promise.all([app, auth, database, firestore, functions]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

class NoHeadFootLayout extends Layout {
  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AutoRedirectToHomeIfAuth {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

const DefaultHelmet = () => (
      <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i,900,900i&display=swap" rel="stylesheet"/>
      <link rel="stylesheet" type="text/css" href="/fonts/css/fontawesome-all.min.css"/>    
      {/* <script src={withPrefix('template/js/custom.js')} type="text/javascript" /> */}
      </Helmet>
    );

const AppWithAuthentication = withAuthentication(({ children }) => (
  <Fragment>
    <DefaultHelmet/> 
    <div id="page">
      <Header/>
      {children}
      {/* <Navigation /> */}
      <Footer/>
    </div>
  </Fragment>
));

class AutoRedirectToHomeIfAuth extends Component{
  authUser = null;
  componentDidMount() {
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    if(this.authUser)
      navigate(ROUTES.HOME)
  }

  render() {
    return (
      <Fragment>
      <DefaultHelmet/>
      {this.authUser? null : (<div id="page">{this.props.children}</div>) }  
      </Fragment>
    );
  }

};

const NoHeadFootAppWithAuthentication = withAuthentication(AutoRedirectToHomeIfAuth);

export default Layout;

export {DefaultHelmet,NoHeadFootLayout};
