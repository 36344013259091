const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_APP_ID,
  measurementId: process.env.GATSBY_MEASUREMENT_ID
};

// const config = {
//   apiKey: "AIzaSyCUhmC_Z2XA_9sACFeeTeJA5jOWlAq4OGM",
//   authDomain: "vip-iot-plus.firebaseapp.com",
//   databaseURL: "https://vip-iot-plus.firebaseio.com",
//   projectId: "vip-iot-plus",
//   storageBucket: "vip-iot-plus.appspot.com",
//   messagingSenderId: "698904180103",
//   appId: "1:698904180103:web:9bb04330fa9df0db93cf22",
//   measurementId: "G-WTP614GY3B"
// };

class Firebase {
  constructor(app) {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    // console.log(process.env.GATSBY_API_KEY);
    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.database = app.database();
    this.firestore = app.firestore();
    this.functions = app.app().functions('asia-east2');
    // if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
    //   this.functions.useFunctionsEmulator("http://localhost:5001");
    // }

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // console.log(dbUser)
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.firestore.collection("users").doc(`${uid}`); //this.database.ref(`users/${uid}`);

  users = () => this.firestore.collection("users");

  // *** Device API ***

  device = devid => this.firestore.collection(`devices`).doc(`${devid}`);

  devices = () => this.firestore.collection('devices');

  devicesByIdList = (ids) => this.firestore.collection('devices').where('__name__', 'in', ids);
  
  devicesIdOfUser = uid => this.user(uid).collection("devices");

  
  // get 
  getDevicesByIdList = async (deviceIds) =>{
    const devices = {}
    for(let i =0 ;i < deviceIds.length; i+=10){
      const snapshot = await this.devicesByIdList(deviceIds.slice(i,i+10)).get();
      snapshot.forEach(doc => {
        let deviceObject = doc.data();
        if (deviceObject) {
          devices[doc.id] = {
            ...deviceObject,
            key: doc.id,
          }
        }
      });
    }
    return devices
  }

  getDevicesOfUser = async (uid) => {
    let deviceIds = []
    let collectionData = await this.devicesIdOfUser(uid).get()
    collectionData.forEach(dev =>{
      deviceIds.push(dev.id);
    })
    return this.getDevicesByIdList(deviceIds)
  } 

  // cloud functions
  addDevice = (devId,secret,userId) => this.functions.httpsCallable('device-addDevice')({devId,secret,userId})
  setSwitch = (devId,port,state,params) => {
    console.log(devId,port,state,params)
    return this.functions.httpsCallable('control-setSwitch')({devId,port,state,params})
  }

  //  Real-time databaseq
  deviceStates = (devId) => this.database.ref(`deviceStates/${devId}`)


  

}

let firebase;

function getFirebase(app, auth, database,functions) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database,functions);
  }

  return firebase;
}

export default getFirebase;
