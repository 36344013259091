
import React, { Component } from 'react';


class Header extends Component {
  componentDidMount() {

  }

  render() {
    return (
        <div className="header header-fixed header-logo-center">
            <a href="index.html" className="header-title">ViP IoT Plus</a>
            <a href="index.html" className="back-button header-icon header-icon-1"><i className="fas fa-arrow-left"></i></a>
            <a href="index.html" data-toggle-theme className="header-icon header-icon-4"><i className="fas fa-lightbulb"></i></a>
        </div>        
    );
  }
}


export default Header;
