import { Link } from 'gatsby';
import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';

class Footer extends Component {
  componentDidMount() {

  }

  render() {
    return (
        <div id="footer-bar" className="footer-bar-1">
        <Link to={ROUTES.HOME}><i className="fa fa-home"></i><span>Home</span></Link>
        <Link to={ROUTES.DEVICES_LIST}><i className="fa fa-microchip"></i><span>Devices</span></Link>
        <Link to={ROUTES.ACCOUNT}><i className="fa fa-user"></i><span>Account</span></Link>
        <Link to={ROUTES.SETTINGS}><i className="fa fa-cog"></i><span>Settings</span></Link>
        </div>    
    );
  }
}


export default Footer;
